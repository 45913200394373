<style lang="scss" scoped></style>

<template>
    <div class="page-device-types">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fl">
                    <el-button type="primary" size="medium" icon="el-icon-plus" @click="_edit()">添加设备型号</el-button>
                </div>
                <div class="fr">
                    <el-input class="c-el-input mrgr5 mrgb5" placeholder="名称" clearable v-model="filter.keywords" size="medium"></el-input>
                    <el-button type="primary" @click="_search()" size="medium">查询</el-button>
                </div>
            </div>
        </div>
        <div>
            <el-table :data="tableData" border fit highlight-current-row row-key="id">
                <el-table-column prop="id" label="ID" width="180"></el-table-column>
                <el-table-column prop="name" label="名称"></el-table-column>
                <el-table-column prop="interactorTypeDesc" label="类型"></el-table-column>
                <el-table-column prop="deposit" label="押金"></el-table-column>
                <el-table-column prop="annualFree" label="年费"></el-table-column>
                <el-table-column prop="slotCount" label="插槽数量"></el-table-column>
                <el-table-column label="租金">
                    <template slot-scope="scope" v-if="scope.row.rentSettings">
                        <div>
                            {{ scope.row.rentSettings.freeMinutes }}分钟内免费,
                            <span v-if="scope.row.rentSettings.startRent > 0">起价{{ scope.row.rentSettings.startRent }}元/{{ scope.row.rentSettings.startRentUnitDesc }},</span>
                            超出后{{ scope.row.rentSettings.rentPrice }}元/{{ scope.row.rentSettings.rentTimeUnitDesc }},
                            日封顶{{ scope.row.rentSettings.maxDayRent }}元
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="sort" label="排序" width="80"></el-table-column>
                <el-table-column label="是否激活" width="80">
                    <template slot-scope="scope" >
                    <el-tag type="warning" size="mini">{{scope.row.isActive? "是" : "否"}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="100px" fixed="right">
                    <template slot-scope="scope">
                        <el-dropdown trigger="click">
                            <el-button type="primary" plain size="mini">更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="_edit(scope.row)">修改</el-dropdown-item>
                                <el-dropdown-item @click.native="deleteDeviceType(scope.row)">删除</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
                    :current-page="filter.page" :page-sizes="appPageSizes" :page-size="filter.size" 
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
        <!--编辑-->
        <edit :ref="refEdit" @refreshData="getDataList()"></edit>
    </div>
</template>

<script>
import * as funDevice from "@/api/device/index"
import edit from "./components/TypeEdit"
export default {
    name: "pageDeviceTypes",
    components: { edit },
    data() {
        return {
            refEdit: "refDeviceTypesToTypeEdit",
            tableItems: {},
            tableData: [],
            total: null,
            isPagination: false,
            filter: {
                page: 1,
                size: window.$common.appPageSize,
                keywords: ""
            }
        }
    },
    activated() {
        this.getDataList()
    },
    created() {
        this.getDataList()
    },
    methods: {
        handleSizeChange(val) {
            this.filter.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            this.filter.page = val
            this.getDataList()
        },
        async getDataList() {
            window.$common.fullLoading()
            funDevice.GetDeviceTypeList(this.filter).then(response => {
                window.$common.closeFullLoading()
                this.tableItems = response
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items
                } else {
                    this.tableData = []
                }
            })
        },
        _edit(row) {
            this.$refs[this.refEdit].open(row)
        },
        async deleteDeviceType(row) {
            this.confirm(`确认要删除设备型号【${row.name}】吗？`).then(() => {
                window.$common.fullLoading()
                funDevice.DeviceTypeDelete({ id: row.id }).then(() => {
                    this.successMsg("删除成功")
                    this.getDataList()
                })
            })
        },
        _search() {
            this.filter.page = 1
            // this.filter.keywords = this.keywords
            this.getDataList()
        }
    }
}
</script>